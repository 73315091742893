/*
 Default: #fe5619 #111D4A;
 */



h2, a:focus {
    color: #111D4A;
}
.button, .center-wrap button button {
	border: 1px solid #111D4A;
    background-color: #111D4A;
}
.plan .button:hover {
	border-color: #111D4A;
}
.plan .button a, .glyph i {
	color: #111D4A;
}

.plan.featured .button {
	background-color: #111D4A;
}
.social-icons ul li a:hover {
	background-color: #111D4A;
} 
.navbar-light .navbar-nav > .nav-item:hover,
.navbar-light .navbar-nav > .nav-item:active {
	background-color: #006e90;
}

.owl-thumb-item i {
    color: #111D4A;
}
.navbar-light .navbar-nav .nav-link.active{
	background-color: #006e90 !important;
}

.navbar-light.fixed-top, .search-modal, .home-video-area::before, .owl-thumb-item.active, .owl-thumb-item:hover {
    background-color: #111D4A;
}
.navbar-light.fixed-top {
    background-color: #111D4A !important;
}
.filter-menu button.programs-filter-btn-active, .filter-menu button:hover {
	background-color: #111D4A;
}
.work-details .icon li span, .team-box .title, .team-box .icon li a, .team-slides .owl-nav div {
	color: #111D4A !important;
}
.team-box .icon li a:hover {
	background: #111D4A;
}
.date-box {
	background-color: #111D4A;
}
.blog-item:hover .title-meta h2 a {
    color: #111D4A;
}
.post-meta ul li a:hover {
	color: #111D4A;
  }
  .pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
	background-color: #111D4A;
	border-color: #111D4A;
}
.side-widget h3::before { 
	background: #111D4A;
}
.search-form .btn-default {
	border-color: #111D4A;
	background: #111D4A;
}
.side-widget .list-group li a:hover {
	color: #111D4A;
}
.single-post h4 a:hover {
	color: #111D4A;
}
.list-tags li a:hover {
    background-color: #111D4A;
}
.sharing-link ul li i:hover {
    background-color: #111D4A;
}
.content-box:hover .box-icon-effect-1a .box-icon i {
    color: #111D4A;
}
.work-details .box-content{
	background: #111D4A;
}
.comments-title::before {
	background: #111D4A;
}
.single-comment .reply:hover {
	background-color: #111D4A;
}
.video-area:before {
	background: #111D4A;
}
.plus, .post-meta ul li .fa {
	color: #111D4A;
} 
.plus:hover {
	color: #111D4A;
}
.tab-menu ul li.active a {
	background-color: #111D4A;
}
.plan.featured .listing-badges .featured {
	color: #111D4A;
}
.plan.featured .plan-price, .content-box.color-effect-1, .footer  {
	background-color: #111D4A;
}
.single-testimonial-item i, .partners-slides .owl-nav div {
    color: #111D4A !important;
}
.client-info h3, .testimonial-slides .owl-nav div {
	color: #111D4A !important;
 }
 .panel-title a:before, .panel-title a.collapsed:before {
	color: #111D4A;
	border: 1px solid #111D4A;
}
.social-icons.bottom ul li a {
	background-color: #006e90;
}
.social-icons.bottom ul li a:hover {
    color: #006e90;
}
.about-info i {
    color:#111D4A;
}
.about-info:hover {
    background-color: #111D4A;
}
.plan .btn-a {
    color: #111D4A;
}
